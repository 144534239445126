import React, { useState } from "react";
import { usePostHog } from "posthog-js/react";

import Icon from "../../base/Icon/Icon";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import IconButton from "../../base/IconButton/IconButton";

const PayPalButton = () => {
  const [ isButtonVisible, setIsButtonVisible ] = useState(true);
  const [ isMessageVisible, setIsMessageVisible ] = useState(false);
  const posthog = usePostHog();

  const handleButtonClick = () => {
    posthog.capture("PayPal button clicked", { component: "PayPalButton" });

    setIsButtonVisible(false);
    setIsMessageVisible(true);
  };

  const handleCloseMessage = () => {
    setIsMessageVisible(false);
  };

  return (
    <>
      { /* PayPal button */ }
      <div className="flex flex-col items-center">
        { isButtonVisible && (
          <button
            type="button"
            data-component={PayPalButton.name}
            className="px-4 py-2 bg-[#FFD700] w-full h-10 flex justify-center items-center rounded mt-2"
            onClick={handleButtonClick}
          >
            <Icon icon="paypal" size="large" className="h-auto" />
          </button>
        ) }

        { /* Message */ }
        { isMessageVisible && (
          <div className={`flex flex-col p-4 gap-y-4 transition-opacity duration-500 ease-in-out bg-white mt-4
          `}>
            <div className="flex items-center justify-between w-full">
              <TextSubtitle tag="h1" size={1}>We&apos;re working on it!</TextSubtitle>
              <IconButton icon="cross" size="small" onClick={handleCloseMessage} label=""/>
            </div>
            <TextBody tag="p" size={2} align="default">
             We hope to make PayPal available soon. In the meantime, please complete your payment with another option.
            </TextBody>
          </div>
        ) }
      </div>

    </>
  );
};

export default PayPalButton;
