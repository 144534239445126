import {
  ITaggedSummaryGroupProps,
} from "apps/website/components/feature/TaggedSummary/TaggedSummaryGroup";

export const getTagGroups = (values: Record<string, string>): ITaggedSummaryGroupProps[] => {
  const groups: ITaggedSummaryGroupProps[] = [];

  const allergies = values.$catAllergies?.split(",") || [];

  const calorieTags = values.$catKcalPerDay ? [ values.$catKcalPerDay ] : [];

  const aboutCatKeys = [ "$catAge", "$catWeight", "$catSex", "$catPedigreeOrMoggie", "$catBreed", "$catActivity", "$catBodyCondition" ];
  const aboutCatTags: string[] = [];

  aboutCatKeys.forEach((key) => {
    if (values[key] && values[key].trim() !== "") {
      const value = key === "$catWeight" ? `${values[key]}kg` : values[key];
      aboutCatTags.push(value);
    }
  });

  if (aboutCatTags.length) {
    groups.push({
      title: "Based on:",
      tags: aboutCatTags,
    });
  }

  if (allergies.length) {
    groups.push({
      title: `We made sure ${values.$catPronounHisHerTheir} plan doesn't contain any:`,
      tags: allergies,
    });
  }

  if (calorieTags.length) {
    groups.push({
      title: `${values.$catPronounHeSheThey} needs to eat:`,
      tags: calorieTags,
    });
  }

  return groups;
};
