import { FC } from "react";

import { Theme } from "apps/website/maps/Theme.map";

import ImageWithBadge, {
  IImageWithBadgeProps,
} from "../../feature/ImageWithBadge/ImageWithBadge";
import Container from "../../layout/Container/Container";
import Section from "../../layout/Section/Section";
import { getSectionSize } from "../../layout/Section/Section.utils";

export interface ISectionImageWithBadgeProps {
  imageWithBadgeProps: IImageWithBadgeProps;
  theme: Theme
}

const SectionImageWithBadge: FC<ISectionImageWithBadgeProps> = ({ imageWithBadgeProps, theme }) => (
  <Section component={SectionImageWithBadge.name} size={getSectionSize(theme)}>
    <Container>
      <ImageWithBadge {...imageWithBadgeProps}/>
    </Container>
  </Section>
);

export default SectionImageWithBadge;
