import {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  Suspense,
} from "react";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import Summary from "apps/website/components/form/Summary/Summary";
import {
  AddressValue,
  FieldName,
  IPlanSummaryResponse,
  ISummaryDiscountItem,
  ISummaryItem,
  SummaryChildren,
} from "@forms/schema";
import {
  SummaryDiscountCode,
} from "apps/website/components/form/Summary/SummaryDiscountCode/SummaryDiscountCode";
import { getFieldValue, useCustomerStore, useDiscountStore } from "@./state";
import { DisplayState } from "@/constants/state";
import useFormsServiceStore, {
  getFlowFieldValuesForAction,
  getFlowProduct,
} from "libs/state/src/lib/stores/useFormServiceStore";
import Text from "apps/website/components/base/Text/Text";
import { IImageProps } from "apps/website/components/base/Image/Image";
import Column from "apps/website/components/layout/Column/Column";
import SummaryDetailed from "apps/website/components/form/Summary/SummaryDetailed/SummaryDetailed";
import { useAPI } from "apps/website/hooks/useAPI";
import { useDatadog } from "@auth/client-sdk-react";

import { useTrackPageView } from "../../../../contexts/tracking";
import {
  getActionTrackingInfo,
} from "../../../../utils/tracking/getActionTrackingInfo";

type IFormStructureSummary = {
  title: string;
  image?: IImageProps;
  child?: SummaryChildren;
  defaultDiscountCode?: string;
  flowId: string;
  isExpandedByDefault?: boolean;
  canToggle?: boolean;
  action: string;
  allowReferralCode?: boolean;
  discountCodeQueryParam?: string;
  sendCheckoutInitiatedEvent?: boolean;
};

export const FormStructureSummary: FC<IFormStructureSummary> = ({
  title,
  image,
  child,
  defaultDiscountCode,
  flowId,
  isExpandedByDefault,
  canToggle,
  action,
  allowReferralCode,
  discountCodeQueryParam,
  sendCheckoutInitiatedEvent,
}) => {
  const [ flowFieldValues, setFlowFieldValue ] = useFormsServiceStore(
    (x) => ([ x.flows.find((f) => f.id === flowId), x.setFlowFieldValue ]),
  );

  const discountCodesInStore = useDiscountStore((x) => x.discountCodes);
  const { customerIds } = useCustomerStore();

  const fieldValues = getFlowFieldValuesForAction(flowId);
  const prevLitterDeliveryDateRef = useRef<string>();
  const prevFreshDeliveryDateRef = useRef<string>();

  const [ summaryData, setSummaryData ] = useState<IPlanSummaryResponse | undefined>(undefined);
  const [ state, setState ] = useState(DisplayState.PROCESSING);
  const formsActionsAPI = useAPI().FormActions;
  const posthog = usePostHog();
  const datadog = useDatadog();
  const tracking = useTrackPageView();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const getSummaryData = useCallback(
    async (formDiscountCode: string, userDiscountCode?: string): Promise<IPlanSummaryResponse | undefined> => {
      const fieldSubmitValuesMap = getFlowFieldValuesForAction(flowId);
      const featureFlags = posthog.featureFlags.getFlagVariants();
      if (userDiscountCode) {
        fieldSubmitValuesMap?.set("discountCode", { value: userDiscountCode });
      }
      const isGoogleShoppingOrHelloFreshOrOtherPartner = !!flowFieldValues?.fields?.find((f) => f.key.fieldName === "flowTrialType" && f.key.linkingId === "0")?.data.submitValue;
      if (!isGoogleShoppingOrHelloFreshOrOtherPartner) {
        fieldSubmitValuesMap?.set("defaultDiscountCode", { value: formDiscountCode });
        if (!userDiscountCode) {
          fieldSubmitValuesMap?.set("discountCode", { value: formDiscountCode });
        }
      }

      try {
        if (fieldSubmitValuesMap.has("freshDeliveryDate") || fieldSubmitValuesMap.has("deliveryDate")) {
          const result = await formsActionsAPI
            .performAction<IPlanSummaryResponse>(
            action,
            fieldSubmitValuesMap,
            getFlowProduct(flowId),
            flowId,
            undefined,
            featureFlags,
            getActionTrackingInfo(cookies),
            datadog.logger,
          );
          if (result.success) {
            const planSummaryResponse = result.responseBody;
            if (planSummaryResponse && sendCheckoutInitiatedEvent) {
              const customerId = customerIds
                .find((dc) => dc.flowId === flowId)
                ?.customerId
                ?.trim();
              if (customerId) {
                const address = getFieldValue(flowId, "address", "0")?.data?.submitValue as AddressValue;
                void tracking.checkoutInitiated({
                  planSummaryResponse,
                  customerId,
                  customerEmail: getFieldValue(flowId, "emailAddress", "0")?.data?.submitValue?.toString()?.trim()
                || undefined,
                  customerFirstName: getFieldValue(flowId, "firstName", "0")?.data?.submitValue?.toString()?.trim()
                || undefined,
                  customerLastName: getFieldValue(flowId, "lastName", "0")?.data?.submitValue?.toString()?.trim()
                || undefined,
                  postcode: getFieldValue(flowId, "postcode", "0")?.data?.submitValue?.toString()?.trim()
                || getFieldValue(flowId, "postcode", "0")?.data?.submitValue?.toString()?.trim()
                || undefined,
                  city: address?.shippingAddress?.city?.trim()
                || undefined,
                  phoneNumber: address?.shippingAddress?.phone?.trim()
                || undefined,
                });
              } else {
                console.error(`Should never happen: No customerId set in ${flowId}`);
              }
            }
            return planSummaryResponse;
          }
        }
      } catch (error) {
        console.error(error);
        setState(DisplayState.ERROR);
      }
    },
    [ action, flowId, formsActionsAPI, flowFieldValues, tracking ],
  );

  const loadSummary = useCallback(async () => {
    setState(DisplayState.PROCESSING);
    setSummaryData(undefined);
    if (discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode) {
      const result = await getSummaryData(defaultDiscountCode ?? "", discountCodesInStore.find((dc) => dc.flowId === flowId)?.discountCode);
      setSummaryData(result);
    } else {
      const result = await getSummaryData(defaultDiscountCode ?? "");
      setSummaryData(result);
    }
  }, [ discountCodesInStore, flowId, getSummaryData, defaultDiscountCode ]);

  const stringifyMapResultsFromKey = useCallback(
    (key: FieldName) => JSON.stringify(fieldValues.get(key)),
    [ fieldValues ],
  );

  const hasADeliveryDateChanged = useMemo(() => (
    (stringifyMapResultsFromKey("freshDeliveryDate") !== prevFreshDeliveryDateRef.current) ||
      (stringifyMapResultsFromKey("deliveryDate") !== prevLitterDeliveryDateRef.current)
  ), [ stringifyMapResultsFromKey ]);

  useEffect(() => {
    void loadSummary();
  }, [ loadSummary ]);

  useEffect(() => {
    if (summaryData) {
      setState(DisplayState.READY);
      if (summaryData.costTodayRaw !== undefined) {
        setFlowFieldValue(flowId, "costTodayRaw", {
          submitValue: summaryData.costTodayRaw, displayValue: summaryData.costTodayRaw,
        });
      }
      if (summaryData.costPerDay !== undefined) {
        setFlowFieldValue(flowId, "costPricePerDay", {
          submitValue: summaryData.costPerDay, displayValue: summaryData.costPerDay,
        });
      }

      if (summaryData.costRecurring !== undefined) {
        setFlowFieldValue(flowId, "costRecurring", {
          submitValue: summaryData.costRecurring, displayValue: summaryData.costRecurring,
        });
      }
      if (summaryData.multicatDiscount !== undefined) {
        if (summaryData.multicatDiscount.preDiscountCostPerDay !== undefined) {
          setFlowFieldValue(flowId, "preMulticatDiscountCostPerDay", {
            submitValue: summaryData.multicatDiscount.preDiscountCostPerDay,
            displayValue: summaryData.multicatDiscount.preDiscountCostPerDay,
          });
        }
        if (summaryData.multicatDiscount.preDiscountCostRecurring !== undefined) {
          setFlowFieldValue(flowId, "preMulticatDiscountCostRecurring", {
            submitValue: summaryData.multicatDiscount.preDiscountCostRecurring,
            displayValue: summaryData.multicatDiscount.preDiscountCostRecurring,
          });
        }
      }
    }
  }, [ summaryData ]);

  useEffect(() => {
    if (hasADeliveryDateChanged) {
      void loadSummary();
      prevFreshDeliveryDateRef.current = stringifyMapResultsFromKey("freshDeliveryDate");
      prevLitterDeliveryDateRef.current = stringifyMapResultsFromKey("deliveryDate");
    }
  }, [ fieldValues, hasADeliveryDateChanged, loadSummary, stringifyMapResultsFromKey ]);

  const summaryItems = (): (ISummaryItem | ISummaryDiscountItem)[] => {
    let items: (ISummaryItem | ISummaryDiscountItem)[] = [];
    if (!summaryData) return items;
    items = [ ...(summaryData.items), ...[ summaryData?.discount ] ].filter((item) => !!item)
      .map((a) => a as ISummaryItem) ?? [];
    return items;
  };

  return (
    <Column>
      { summaryData && (
        <>
          { summaryData.type === "detailed" ?
            <SummaryDetailed
              { ...summaryData }
              title={summaryData.title ?? title}
              image={image}
              items={summaryItems()}
              state={state}
              flowSlug={flowId}
              reloadSummary={loadSummary}
            >
              <>
                { (!summaryData?.items?.length) && (
                  <div className="text-orange">
                    <Text color="inherit">We can&apos;t fetch your summary, please try again.</Text>
                  </div>
                ) }
                { child === "summary_discount" && <Suspense><SummaryDiscountCode
                  action={action}
                  flowId={flowId}
                  defaultDiscountCode={defaultDiscountCode}
                  allowReferralCode={allowReferralCode}
                  queryParam={discountCodeQueryParam}
                /></Suspense> }
              </>
            </SummaryDetailed>
            : <Summary
              title={summaryData.title ?? title}
              image={image}
              titlePrice={summaryData?.titlePrice}
              items={summaryItems()}
              footer={summaryData?.footer}
              state={state}
              isExpandedByDefault={isExpandedByDefault}
              canToggle={canToggle}
            >
              <>
                { (!summaryData?.items?.length) && (
                  <div className="text-orange">
                    <Text color="inherit">We can&apos;t fetch your summary, please try again.</Text>
                  </div>
                ) }
                { child === "summary_discount" && <Suspense><SummaryDiscountCode
                  action={action}
                  flowId={flowId}
                  defaultDiscountCode={defaultDiscountCode}
                  allowReferralCode={allowReferralCode}
                  queryParam={discountCodeQueryParam}
                /></Suspense> }
              </>
            </Summary>
          }
        </>
      ) }
    </Column>
  );
};
