import { Theme } from "apps/website/maps/Theme.map";
import {
  IStoryBlokComponent,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";

import SectionComparisonModal, {
  TIComparisonModalTableRowCellIcon,
} from "../../section/SectionComparisonModal/SectionComparisonModal";

interface IStoryBlokComparisonModalTableHeader {
  image: IStoryBlokImage;
  title: string;
}

interface IStoryBlokComparisonModalTableKatKinHeader extends IStoryBlokComparisonModalTableHeader {
  with_logo: boolean;
}

interface IStoryBlokComparisonModalTableCell {
  icon: TIComparisonModalTableRowCellIcon;
  body: string;
}

interface IStoryBlokComparisonModalTableRow {
  competitor: [IStoryBlokComparisonModalTableCell];
  katkin: [IStoryBlokComparisonModalTableCell];
}

interface IStoryBlokComparisonModalTable {
  competitor_header:[ IStoryBlokComparisonModalTableHeader];
  katkin_header: [IStoryBlokComparisonModalTableKatKinHeader];
  rows: IStoryBlokComparisonModalTableRow[];
}

export interface IStoryBlokComparisonModal {
  title: string;
  cta_text: string;
  theme: Theme;
  table: [IStoryBlokComparisonModalTable];
}

export const StoryBlokComparisonModal = ({ blok }: IStoryBlokComponent<IStoryBlokComparisonModal>) => (
  <>
    <SectionComparisonModal
      modalTitle={ blok.title }
      ctaText={ blok.cta_text }
      theme={blok.theme}
      table={{
        headers: {
          competitor: blok.table[0].competitor_header.map((cell) => ({
            title: cell.title,
            image: getStoryBlokImage(cell.image),
          }))[0],
          katkin: blok.table[0].katkin_header.map((cell) => ({
            title: cell.title,
            image: getStoryBlokImage(cell.image),
            withLogo: cell.with_logo,
          }))[0],
        },
        rows: blok.table[0].rows.map((row) => (
          {
            competitor: {
              icon: row.competitor[0].icon,
              body: renderStoryBlokRichText(row.competitor[0].body),
            },
            katkin: {
              icon: row.katkin[0].icon,
              body: renderStoryBlokRichText(row.katkin[0].body),
            },
          }
        )),
      }}
    />
  </>
);
