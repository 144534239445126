import { FC, useMemo } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import highlightTextWithHTML from "apps/website/utils/website/highlightTextWithHTML";

import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../base/Text/TextBody/TextBody";
import Spacer from "../../layout/Spacer/Spacer";

export interface IHighlighterCalloutProps {
  title: string;
  body: string;
  theme?: Theme;
}

const HighlighterCallout: FC<IHighlighterCalloutProps> = ({ title, body, theme = "light" }) => {
  const highlightedTitle = useMemo(() => highlightTextWithHTML({ text: title }), [ title ]);
  return (
    <div data-component={ HighlighterCallout.name } className={ `${theme === "default" ? null : "p-4"} ${themeRootClassMap[theme]}` }>
      <TextSubtitle
        tag="h3"
        size={ 2 }
        whitespace="noWrap"
        data-testid="HighlighterCalloutTitle"
      >
        <span dangerouslySetInnerHTML={ { __html: highlightedTitle } }></span>
      </TextSubtitle>
      <Spacer size="md" />
      <TextBody size={ 3 } data-testid="HighlighterCalloutBody">{ body }</TextBody>
    </div>
  );
};

export default HighlighterCallout;
