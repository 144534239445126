import { Suspense } from "react";

import {
  IStoryBlokComponent,
  IStoryBlokCta,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";
import {
  IRichTextOptions,
  renderStoryBlokRichText,
} from "apps/website/utils/storyblok/text";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import SectionFreeFlow from
  "apps/website/components/section/SectionFreeFlow/SectionFreeFlow";
import {
  AvailableSize,
  AvailableTag,
} from "apps/website/components/feature/Freeflow/FreeFlowTitle/FreeFlowTitle.map";
import FreeFlowTitle, {
  TFreeFlowTitleMargin,
} from "apps/website/components/feature/Freeflow/FreeFlowTitle/FreeFlowTitle";
import FreeFlowRichText from "apps/website/components/feature/Freeflow/FreeFlowRichText/FreeFlowRichText";
import {
  FreeFlowRichTextColor,
} from "apps/website/components/feature/Freeflow/FreeFlowRichText/FreeFlowRichText.map";
import FreeFlowRawHtml from
  "apps/website/components/feature/Freeflow/FreeFlowRawHtml/FreeFlowRawHtml";
import FreeFlowImage from
  "apps/website/components/feature/Freeflow/FreeFlowImage/FreeFlowImage";
import {
  Align,
  BreakpointSize,
  Display,
  Size,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import {
  Size as SectionSize,
} from "apps/website/components/layout/Spacer/Spacer.map";
import { getStoryBlokCtas } from "apps/website/utils/storyblok/links";
import {
  Size as ContainerSize,
} from "apps/website/components/layout/Container/Container.map";

import FreeFlowCTA from "../../feature/Freeflow/FreeFlowCTA/FreeFlowCTA";

export interface IStoryBlokFreeFlowTitle {
  tag: AvailableTag;
  align: Align;
  display: Display;
  size: AvailableSize;
  text: string;
  _uid: string;
  component: "free_flow_title";
  margin?: TFreeFlowTitleMargin;
}

type StoryBlokFreeFlowRichTextSize = "default" | "small";
export interface IStoryBlokFreeFlowRichText {
  content: string;
  align: Align;
  disable_spacer: boolean;
  size: StoryBlokFreeFlowRichTextSize;
  _uid: string;
  color: FreeFlowRichTextColor;
  component: "free_flow_rich_text";
}
export interface IStoryBlokFreeFlowRawHtml {
  content: string;
  _uid: string;
  component: "free_flow_raw_html";
}

export interface IStoryBlokFreeFlowCTAs {
  ctas: IStoryBlokCta[];
  _uid: string;
  component: "free_flow_ctas";
}
export interface IStoryBlokFreeFlowImage {
  image: IStoryBlokImage;
  container: ContainerSize
  _uid: string;
  component: "free_flow_image";
}

export interface IStoryBlokFreeFlow {
  component?: "section_free_flow";
  theme?: Theme;
  items: (
    IStoryBlokFreeFlowTitle |
    IStoryBlokFreeFlowRichText |
    IStoryBlokFreeFlowRawHtml |
    IStoryBlokFreeFlowImage |
    IStoryBlokFreeFlowCTAs
  )[];
  size?: SectionSize;
  containerSize?: ContainerSize;
  richtextOptions?: IRichTextOptions,
}

const fontSizeMap: Record<StoryBlokFreeFlowRichTextSize, Size | BreakpointSize> = {
  default: "default",
  small: legacySizeCollectionMap.body2Xs,
};

export const StoryBlokFreeFlow = ({ blok }: IStoryBlokComponent<IStoryBlokFreeFlow>) => (
  <SectionFreeFlow theme={blok.theme || "default"} size={blok.size || "xl"} containerSize={blok.containerSize || "default"}>
    <>
      { blok.items.map((item) => {
        let content = <></>;
        if (item.component === "free_flow_title") {
          content = <FreeFlowTitle
            key={item._uid}
            tag={item.tag}
            size={item.size}
            align={item.align}
            display={item.display}
            margin={ item.margin }
          >
            { item.text }
          </FreeFlowTitle>;
        }
        if (item.component === "free_flow_ctas") {
          content =
          <Suspense>
            <FreeFlowCTA
              key={item._uid}
              ctas={getStoryBlokCtas(item.ctas)}
            />
          </Suspense>;
        }
        if (item.component === "free_flow_rich_text") {
          content = <FreeFlowRichText
            key={item._uid}
            align={item.align}
            withSpacer={!item.disable_spacer}
            size={fontSizeMap[item.size] ?? "default"}
            color={item.color ?? "default"}
          >
            { renderStoryBlokRichText(item.content, blok.richtextOptions) }
          </FreeFlowRichText>;
        }
        if (item.component === "free_flow_raw_html") {
          content = <FreeFlowRawHtml key={item._uid} html={item.content} />;
        }
        if (item.component === "free_flow_image") {
          content = <FreeFlowImage
            key={item._uid}
            image={getStoryBlokImage(item.image, true, ImageMax.CONTAINER_2XL)}
            container={item.container}
          />;
        }
        return content;
      }) }
    </>
  </SectionFreeFlow>
);
