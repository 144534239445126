"use client";

import {
  ReactNode,
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { usePostHog } from "posthog-js/react";

import { useNewFormsServiceStore } from "@./state";
import { DisplayState } from "@/constants/state";
import {
  ISummaryItem,
  ISummaryDiscountItem,
  ISummaryDetailFreshDiscounts,
  ISummaryDetailFreshSprinkles,
} from "@forms/schema";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Table from "apps/website/components/feature/Table/Table";
import {
  Align,
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import { useAPI } from "apps/website/hooks/useAPI";
import { MealPlanType } from "libs/api/customer/src/lib/types/cats";
import { PouchSize } from "@./cat-calculations";
import Icon from "apps/website/components/base/Icon/Icon";
import TextBody from "apps/website/components/base/Text/TextBody/TextBody";
import { IMealPlanTypeResource } from "@api-clients/customer";
import TextControl from "apps/website/components/base/Text/TextControl/TextControl";
import { LIST_FORMATTER } from "apps/website/utils/misc/arrays";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import SectionArrow from "apps/website/components/section/SectionDownArrow/SectionArrow";
import TextSubtitle from "apps/website/components/base/Text/TextSubtitle/TextSubtitle";
import Tag from "apps/website/components/base/Tag/Tag";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import { pluralise } from "apps/website/utils/misc/strings";

import IconButton from "../../../base/IconButton/IconButton";
import { LoadingSpinner } from "../../LoadingSpinner/LoadingSpinner";
import {
  SummaryDiscountCode,
} from "../SummaryDiscountCode/SummaryDiscountCode";

export interface ISummaryDetailed {
  title: string;
  image?: IImageProps;
  items: (ISummaryItem | ISummaryDiscountItem)[];
  footer?: ISummaryItem[];
  children?: ReactNode;
  state: DisplayState;
  reloadSummary: () => void;
  flowSlug: string;
  costPerDay?: string;
  discounts?: ISummaryDetailFreshDiscounts;
  totalTodayNotDiscounted?: number,
  totalTodayDiscounted?: number,
  pricePerMeal?: number,
  chickenSprinklesData?: ISummaryDetailFreshSprinkles;
  isTrial?: boolean;
}

const cellStyle = "px-2 py-2 lg:py-2";
const leftCellStyle = `text-left w-[66.666%] ${cellStyle}`;
const rightCellStyle = `text-right ${cellStyle} flex flex-col justify-end`;

const SummaryDetailed: FC<ISummaryDetailed> = (props) => {

  const posthog = usePostHog();

  if (props.isTrial && posthog.getFeatureFlag("remove-why-fresh-from-suf") === "test") {
    return (<SummaryDetailedTest { ...props } />);
  }

  return (<SummaryDetailedControl { ...props } />);

};

const TestSummaryTH: FC<{ children: ReactNode, className?: string }> = ({ children, className }) => (
  <th className={ `px-2 pb-2 lg:px-4 lg:pb-4 ${className}` }>{ children }</th>
);
const TestSummaryTD: FC<{ children: ReactNode, className?: string }> = ({ children, className }) => (
  <td className={ `px-2 pb-2 lg:px-4 lg:pb-4 last:text-right ${className}` }>{ children }</td>
);

const SummaryDetailedImageDescription: FC<{ title: string, body?: string, align?: Align }> = ({ title, body, align = "center" }) => (
  <div className="flex flex-col justify-center items-center">
    <TextSubtitle size={ 2 } align={ align } whitespace="balance">{ title }</TextSubtitle>
    { body ? (
      <>
        <Spacer size="sm" />
        <TextBody size={ 3 } align={ align } whitespace="balance" className="w-full mb-0">{ body }</TextBody>
      </>
    ) : null }
  </div>
);

const SummaryDetailedTest: FC<ISummaryDetailed> = (props) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();

  // This is taken from aother part of the code outside of the test
  const setCatPlanRemoved = useCallback((catId: string | undefined, state: boolean) => {
    if (!catId) {
      console.error("Tried to remove item with no catId", { catId });
      return;
    }
    setFlowFieldValue(props.flowSlug, "catPlanRemoved", {
      submitValue: state,
      displayValue: state,
    }, catId);
    props.reloadSummary();
  }, [ props.reloadSummary, setFlowFieldValue, props.flowSlug ]);

  const hasChickenSprinkles = useMemo<boolean>(() => (
    !!props.chickenSprinklesData?.quantity
  ), [ props.chickenSprinklesData ]);

  return (

    <div data-component={ SummaryDetailed.name }>
      <div
        className={ `text-left px-2 py-2 mb-2 lg:mb-4 ${themeRootClassMap.dark}` }
        data-theme="dark"
      >
        <div className="flex items-center justify-between">
          <TextSubtitle tag="span" size={ 2 } color="default">Trial box{
            props.discounts?.discountPercentage ?
              <>({ props.discounts.discountPercentage }% off)</> :
              null
          }</TextSubtitle>
          <div data-theme="light-grey" className={ `rounded-full px-2 py-1 ${themeRootClassMap["light-grey"]}` }>
            <TextSubtitle tag="span" size={ 2 } color="default">{ props.costPerDay }/day</TextSubtitle>
          </div>
        </div>
      </div>

      <div className="mb-2 lg:mb-4">
        <Grid className="relative">
          <>
            <Column spans={ hasChickenSprinkles ? 6 : 12 } data-theme="light" className={ `rounded-md ${themeRootClassMap.light}` }>
              <Grid gap="none">
                <>
                  <Column spans={ !hasChickenSprinkles ? 6 : 12 }>
                    <Image image={ { src: "/images/dev/components/DetailedSummary/food.webp", width: 491, height: 255 } } alt="14 days of food" />
                  </Column>
                  { !hasChickenSprinkles ? (
                    <Column spans={6} justify="center" align="center">
                      <SummaryDetailedImageDescription
                        title={`14 days of food${pluralise("", " per cat", props.items.length)}. Perfectly portioned.`}
                        body="No need to feed anything else!"
                        align="default"
                      />
                    </Column>
                  ) : null }
                </>
              </Grid>
            </Column>
            { hasChickenSprinkles ? (
              <>
                <Column spans={ 6 } data-theme="light" className={ `rounded-md ${themeRootClassMap.light}` }>
                  <Image image={ { src: "/images/dev/components/DetailedSummary/nibbles.webp", width: 491, height: 255 }} alt="Nibbles" />
                </Column>
                <div
                  className="w-[36px] h-[36px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-light-grey"
                >
                  <Icon icon="plusHeavy" size="fit" />
                </div>
              </>
            ) : null }
          </>
        </Grid>
      </div>
      { hasChickenSprinkles ? (
        <div className="mb-2 lg:mb-4">
          <Grid>
            <Column spans={6}>
              <SummaryDetailedImageDescription
                title={`14 days of food${pluralise("", " per cat", props.items.length)}`}
                body="Perfectly portioned. No need to feed anything else!"
              />
            </Column>
            <Column spans={ 6 }>
              <SummaryDetailedImageDescription title={ `Chicken Sprinkles${pluralise("", `(x${props.chickenSprinklesData?.quantity})`, props.chickenSprinklesData?.quantity || 1)}`} />
              <Spacer size="sm" />
              <div className="flex space-x-2 justify-center items-center" data-theme="light-grey">
                <TextBody size={2} tag="s" color="secondary">£{ props.chickenSprinklesData?.savings.toFixed(2) }</TextBody>
                <Tag theme="pink">Free gift</Tag>
              </div>
            </Column>
          </Grid>
        </div>
      ) : null }

      <Table className={ "w-full border-collapse bg-white" }>

        <>
          { props.state === DisplayState.READY ? (
            <>
              <tr>
                <TestSummaryTH className="pt-2 lg:pt-4">
                  <TextControl size={ 2 } className="text-green-dark">DPD delivery</TextControl>
                </TestSummaryTH>
                <TestSummaryTD className="pt-2 lg:pt-4"><Tag theme="pink">Free</Tag></TestSummaryTD>
              </tr>
              { props.discounts?.discountPercentage ? (
                <tr>
                  <TestSummaryTH>
                    <TextControl size={ 2 } className="text-green-dark" whitespace="noWrap">{ props.discounts.discountPercentage }% Discount on fresh food</TextControl>
                  </TestSummaryTH>
                  <TestSummaryTD>
                    <TextControl size={ 2 } className="text-green-dark" align="right">-£{ props.discounts.savings.toFixed(2) }</TextControl>
                  </TestSummaryTD>
                </tr>
              ) : null }
              { props.chickenSprinklesData?.quantity ? (
                <tr>
                  <TestSummaryTH>
                    <TextControl size={ 2 } className="text-green-dark" whitespace="noWrap">Savings on Chicken Sprinkles</TextControl>
                  </TestSummaryTH>
                  <TestSummaryTD>
                    <TextControl size={ 2 } className="text-green-dark" align="right">-£{ props.chickenSprinklesData.savings.toFixed(2) }</TextControl>
                  </TestSummaryTD>
                </tr>
              ) : null }
              <tr>
                <td colSpan={2} className="px-2 pb-2 lg:px-4 lg:pb-4">
                  <div className="bg-dark-grey h-0.5 w-full"></div>
                </td>
              </tr>
              <tr>
                <TestSummaryTH className="align-top">
                  <TextControl size={ 2 }>Total today</TextControl>
                </TestSummaryTH>
                <TestSummaryTD className="flex flex-col items-end">
                  <div>
                    <TextBody tag="s" size={ 2 }>£{ ((props.totalTodayNotDiscounted || 0) + (props.chickenSprinklesData?.savings || 0))?.toFixed(2) }</TextBody>&nbsp;
                    <TextControl tag="span" size={ 2 }>£{ props.totalTodayDiscounted?.toFixed(2) }</TextControl>
                  </div>
                  <Spacer size="sm" />
                  <div data-theme="light-grey" className={ `rounded-full w-fit px-2 py-1 ${themeRootClassMap["light-grey"]}` }>£{ props.pricePerMeal?.toFixed(2) }/meal</div>
                </TestSummaryTD>
              </tr>
            </>
          ) : (
            <tr>
              <td colSpan={ 2 } className="flex justify-center items-center py-8">
                <LoadingSpinner color="black" size={ 32 } />
              </td>
            </tr>
          ) }
        </>
      </Table>
      <Spacer size="sm" />
      <div className="flex space-x-2 items-center justify-start">
        <div className="w-[22px] h-[22px]">
          <Icon icon="currency" size="fit" />
        </div>
        <SummaryDiscountCode flowId={ props.flowSlug }
          action="freshPlanSummaryNewCustomer"
          queryParam="discount_code"
          allowReferralCode={ true }
          defaultDiscountCode="FRESH20"
        />
      </div>
      { props.items.map((item) => (
        <div key={item.catName}>
          { item?.removalData && (item.removalData.planRemoved ? (
            <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, false) }
              className="text-3xs text-green disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.addTitle }</button>
          ) : <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, true) }
            className="text-3xs text-red disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.removeTitle }</button>)
          }
        </div>
      )) }
    </div>
  );
};

const SummaryDetailedControl:FC<ISummaryDetailed> = ({
  title,
  image,
  items,
  footer,
  children,
  state,
  reloadSummary,
  flowSlug,
}) => {
  const footerCellStyle = "pb-1";

  return (

    <div data-component={ SummaryDetailed.name }>
      { image?.src && (
        <Image image={ image } alt={ title } />
      ) }
      <Table className={ "w-full border-collapse" }>
        <tr>
          <th
            className={ `text-left px-2 py-2 ${themeRootClassMap.dark}` }
            data-theme="dark"
            scope="col"
            colSpan={ 3 }
          >
            <div className="flex items-center justify-between">
              <Text tag="span" display="subtitle" size={ legacySizeCollectionMap.bodyLg }>{ title }</Text>
            </div>
          </th>
        </tr>
        <>
          { state === DisplayState.READY ? (
            <>

              <>
                { items?.map(((item, index) => (
                  <SummaryDetailedRow
                    key={ index }
                    item={ item }
                    className={ (items.length % 2 === 0 && !children) ? "odd:bg-light-grey even:bg-white" : "even:bg-light-grey odd:bg-white" }
                    reloadSummary={ reloadSummary }
                    flowSlug={ flowSlug }
                  />
                ))) }
              </>
              { children && (
                <tr className={ `h-auto ${items.length % 2 === 0 ? "bg-light-grey" : "bg-white"} ` }>
                  <td className="text-left px-2 pb-2" colSpan={ 3 }>
                    { children }
                  </td>
                </tr>
              ) }
              <>
                { footer?.map(((foot, index) => (
                  <>
                    <tr key={ index } className={ `${index === footer.length - 1 ? themeRootClassMap.light : (items.length % 2 === 0) ? themeRootClassMap.light : themeRootClassMap["light-grey"]}` }>
                      <th colSpan={ 2 } scope="row" className={ `align-top ${footerCellStyle} ${leftCellStyle}` }>
                        <Text tag="span" display={ `${foot?.title?.bold ? "subtitle" : "default"}` } size={ (foot.type && [ "savings", "ongoing" ].includes(foot.type)) ? legacySizeCollectionMap.titleXs : legacySizeCollectionMap.titleSm } color={ foot.type === "savings" ? "success" : "default" }>{ foot?.title?.value }</Text>
                        <Text tag="span" display={ `${foot?.titleExtraDetail?.bold ? "subtitle" : "default"}` } size={ legacySizeCollectionMap.bodySm }>{ foot?.titleExtraDetail?.value }</Text>
                      </th>
                      <td colSpan={ 1 } className={ rightCellStyle }>
                        <div className="flex flex-row justify-end items-center">
                          <Text tag="span" className={ `text-right pr-3 ${foot?.preBody?.strike ? "line-through" : ""}` } display={ `${foot?.preBody?.bold ? "subtitle" : "default"}` } size={ { default: "3xs", md: "2xs" } }>{ foot?.preBody?.value }</Text>
                          <Text
                            tag="span"
                            className={ `text-right ${foot?.body?.strike ? "line-through" : ""}` }
                            display={ `${foot?.body?.bold ? "subtitle" : "default"}` }
                            size={
                              ((foot.type && [ "savings", "ongoing" ].includes(foot.type))) ?
                                legacySizeCollectionMap.titleXs : legacySizeCollectionMap.titleSm
                            }
                            color="default"
                          >{ foot?.body?.value }</Text>
                        </div>
                        <Text className={ `text-right pt-2 ${foot?.bodyExtra?.strike ? "line-through" : ""}` } display={ `${foot?.bodyExtra?.bold ? "subtitle" : "default"}` } size={ legacySizeCollectionMap.titleXs }>
                          { foot?.bodyExtra?.value }
                        </Text>
                      </td>

                    </tr>
                  </>
                ))) }
              </>

            </>
          ) : (
            <tr>
              <td colSpan={ 2 } className="flex justify-center items-center py-8">
                <LoadingSpinner color="black" size={ 32 } />
              </td>
            </tr>
          ) }
        </>
      </Table>
      <SummaryDetailedFutureSaving cats={ items } />
    </div>
  );
};

interface ISummaryDetailedFutureSaving {
  cats: ISummaryItem[];
}

const SummaryDetailedFutureSaving: FC<ISummaryDetailedFutureSaving> = ({ cats }) => {
  const api = useAPI();
  const [ mealPlans, setMealPlans ] = useState<IMealPlanTypeResource[]>();

  useEffect(() => {
    const effectGetMealPlans = async () => {
      const plans = await api.Customer.getAvailableMealPlanTypes();
      setMealPlans(plans);
    };
    void effectGetMealPlans();
  }, []);

  const baseComparisonRecipeCost = useMemo(() => {
    const DEFAULT_PLAN: MealPlanType = "Plan28x28";
    const DEFAULT_TRAY_SIZE: PouchSize = "B";

    return mealPlans?.find((plan) => plan.planType === DEFAULT_PLAN)?.traySize[DEFAULT_TRAY_SIZE].price;
  }, [ mealPlans ]);

  const catsThatQualifyForFutureSavings = useMemo((): ISummaryItem[] | undefined => {
    if (!cats.length || !baseComparisonRecipeCost) return;
    const FUTURE_SAVINGS_QUALIFYING_POUCHES: PouchSize[] = [ "C", "D", "E", "F" ];
    const qualifyingKittens = cats.filter((cat) => (
      cat.isPredictedToSaveInFuture &&
      cat.pouchSize &&
      FUTURE_SAVINGS_QUALIFYING_POUCHES.includes(cat.pouchSize)
    ));
    if (qualifyingKittens.length) return qualifyingKittens;

  }, [ cats, baseComparisonRecipeCost ]);

  const collectiveCatGender = useMemo((): string => {
    if (catsThatQualifyForFutureSavings?.length === 1) return catsThatQualifyForFutureSavings[0].catGender === "BOY" ? "his" : "her";
    return "their";
  }, [ catsThatQualifyForFutureSavings ]);

  const title = useMemo((): string => {
    const catNames = catsThatQualifyForFutureSavings?.map((cat) => cat.catName as string).filter(
      (catName) => typeof catName === "string",
    );
    const catList = !catNames?.length ? "" : LIST_FORMATTER.format(catNames);

    return `You'll pay less as ${catList} grow${catNames?.length === 1 ? "s" : ""}`;

  }, [ catsThatQualifyForFutureSavings ]);

  const getPercentageSaving = useCallback((subscriptionCost: number | undefined): string => {
    if (!baseComparisonRecipeCost || !subscriptionCost) return "";
    if (baseComparisonRecipeCost > subscriptionCost) return "";

    return `${(Math.round(((subscriptionCost - baseComparisonRecipeCost) / subscriptionCost) * 100))}%`;

  }, [ baseComparisonRecipeCost ]);

  return (
    <>
      { (catsThatQualifyForFutureSavings) ? (
        <>
          <SectionArrow backgroundTheme="light" arrowTheme="brand" size="xs" direction="UP" justify="end" className="mr-8 lg:mr-6" />
          <div className={ `p-4 flex space-x-4 ${themeRootClassMap.brand}` } data-theme="brand">
            <div>
              <Icon icon="catProfileKitten" size="medium" />
            </div>
            <div>
              <TextControl size={ 2 }>{ title }</TextControl>
              <Spacer size="sm" />
              <TextBody size={ 3 }>Because kittens need more food than adult cats.
                So expect to save { catsThatQualifyForFutureSavings?.map(
                (cat, index) => (
                  <><TextBody size={ 3 } tag="span" className="font-bold">{ getPercentageSaving(cat.ongoingPriceRaw) } a month once { cat.catName } turns 1 year old</TextBody>{
                    index === catsThatQualifyForFutureSavings.length - 1 ?
                      <></> :
                      index === catsThatQualifyForFutureSavings.length - 2 ?
                        <> and </> :
                        <>, </>
                  }</>),
              ) }. You&apos;ll just need to update { collectiveCatGender } weight.</TextBody>
            </div>
          </div>
        </>
      ) : null }
    </>
  );
};

interface ISummaryDetailedRow {
  item: ISummaryItem,
  className: string,
  reloadSummary: () => void,
  flowSlug: string,
}

const SummaryDetailedRow: FC<ISummaryDetailedRow> = ({
  item,
  className,
  reloadSummary,
  flowSlug,
}) => {
  const { setFlowFieldValue } = useNewFormsServiceStore();
  const [ isExpanded, setIsExpanded ] = useState<boolean>(false);
  const setCatPlanRemoved = useCallback((catId: string | undefined, state: boolean) => {
    if (!catId) {
      console.error("Tried to remove item with no catId", { item });
      return;
    }
    setFlowFieldValue(flowSlug, "catPlanRemoved", {
      submitValue: state,
      displayValue: state,
    }, catId);
    reloadSummary();
  }, [ reloadSummary, setFlowFieldValue, flowSlug ]);

  return (
    <tr
      className={ `h-auto ${className}` }>
      <th scope="row" colSpan={ 2 } className={ leftCellStyle }>
        <div className="flex justify-start items-center w-full">
          <Text className={ `${item?.title?.strike ? "line-through" : ""}` } tag="span" size={ legacySizeCollectionMap.bodySm }>
            { item?.title?.value }
          </Text>
          { item?.titleExtraDetail && (
            <IconButton icon={ isExpanded ? "arrowUp" : "arrowDown" } label="Toggle extra detail" size="2xsmall"
              onClick={ () => setIsExpanded(!isExpanded) } />
          ) }
        </div>
        { isExpanded && item?.titleExtraDetail && (
          <Text className={ `${item.titleExtraDetail.strike ? "line-through" : ""}` }
            size={ { default: "3xs", md: "2xs" } }>{ item?.titleExtraDetail.value }</Text>
        ) }
        { item?.removalData && (item.removalData.planRemoved ? (
          <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, false) }
            className="text-3xs text-green disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.addTitle }</button>
        ) : <button onClick={ () => setCatPlanRemoved(item?.removalData?.linkingId, true) }
          className="text-3xs text-red disabled:opacity-30 disabled:cursor-not-allowed min-w-fit underline text-center font-default opacity-100 lg:hover:opacity-70 transition-opacity duration-300">{ item?.removalData?.removeTitle }</button>)
        }
      </th>
      <td colSpan={ 1 } className={ rightCellStyle }>
        <div className="flex flex-row justify-end items-center">
          <Text tag="span" className={ `text-right pr-3 ${item?.preBody?.strike ? "line-through" : ""}` } display={ `${item?.preBody?.bold ? "subtitle" : "default"}` } size={ { default: "3xs", md: "2xs" } }>{ item?.preBody?.value }</Text>
          <Text
            tag="span"
            className={ `text-right ${item?.body?.strike ? "line-through" : ""}` }
            size={ legacySizeCollectionMap.bodySm }
            display={ `${item?.body?.bold ? item?.body?.uppercase ? "title" : "subtitle" : "default"}` }
            color={ item.body?.highlight ? "success" : "default" }
          >{ item?.body?.value }</Text>
        </div>
        <Text className={ `text-right ${item?.bodyExtra?.strike ? "line-through" : ""}` }
          display={ `${(item?.bodyExtra?.bold) ? "subtitle" : "default"}` } size={ legacySizeCollectionMap.titleSm }>
          { item?.bodyExtra?.value }
        </Text>
      </td>
    </tr>
  );
};

export default SummaryDetailed;
