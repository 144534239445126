import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";

import SectionShortMessageCarousel from "../../section/SectionCarousel/SectionShortMessageCarousel/SectionShortMessageCarousel";
import {
  IShortMessageCarouselItem,
} from "../../feature/Carousel/ShortMessageCarousel/ShortMessageCarouselItem/ShortMessageCarouselItem";

export interface IStoryBlokShortMessageCarousel {
  title?: string;
  subtitle?: string;
  items: IShortMessageCarouselItem[];
  theme?: Theme;
}

export const StoryBlokShortMessageCarousel = ({ blok }: IStoryBlokComponent<IStoryBlokShortMessageCarousel>) => (
  <SectionShortMessageCarousel
    title={ blok.title }
    subtitle={ blok.subtitle }
    items={ blok.items }
    theme={blok.theme}
  />

);
