import { FC } from "react";

import { WithTestID } from "apps/website/types";

import FakeLoading, {
  IFakeLoadingProps,
} from "../../feature/FakeLoading/FakeLoading";
import Container from "../../layout/Container/Container";
import Section from "../../layout/Section/Section";

export interface ISectionFakeLoading extends IFakeLoadingProps, WithTestID {}

const SectionFakeLoading: FC<ISectionFakeLoading> = ({
  ...props
}) => (
  <Section
    component={SectionFakeLoading.name}
    size="none"
  >
    <Container>
      <FakeLoading {...props} />
    </Container>
  </Section>
);

export default SectionFakeLoading;
