import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import {
  Interpolator,
  interpolateString,
} from "libs/form-utils/src/lib/interpolate";
import { getFlowFieldValuesForInterpolation } from "@./state";
import { useFlow } from "apps/website/contexts/flow";

import {
  IStoryBlokComparisonModal,
  StoryBlokComparisonModal,
} from "../StoryBlokComparisonModal";

export const StoryBlokFormComparisonModal = (component: IStoryBlokComponent<IStoryBlokComparisonModal>) => {

  const { flow } = useFlow();

  const formComponent: IStoryBlokComponent<IStoryBlokComparisonModal> = {
    blok: {
      ...component.blok,
      cta_text: interpolateString(
        component.blok.cta_text,
        getFlowFieldValuesForInterpolation(component.flowId ?? flow.slug, component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
        Interpolator.FRONTEND,
      ),
      title: interpolateString(
        component.blok.title,
        getFlowFieldValuesForInterpolation(component.flowId ?? flow.slug, component.linkingId || "0", component.linkingId ? component.interpolation || "GET_RELATED" : "GET_ALL_WITH_REMOVED"),
        Interpolator.FRONTEND,
      ),
    },
  };

  return (
    <>
      <StoryBlokComparisonModal { ...formComponent } />
    </>
  );
};
