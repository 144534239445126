const baaaFreshProductCarouselCard = {
  productName: "BAAA",
  productDescription: <span>{ "100% gently-cooked " }<strong className="font-bold">{ "lamb" }</strong>{ ", with traces of " }<strong className="font-bold">{ "beef" }</strong></span>,
  productImageAlt: "katkin fresh meal Baaa",
  productImageSrc: "/images/products/carousel/baaa.webp",
};

const cluckFreshProductCarouselCard = {
  productName: "CLUCK",
  productDescription: <span>{ "100% gently-cooked" } <strong className="font-bold">{ "chicken" }</strong></span>,
  productImageAlt: "katkin fresh meal Cluck",
  productImageSrc: "/images/products/carousel/cluck.webp",
};

const gobbleFreshProductCarouselCard = {
  productName: "GOBBLE",
  productDescription: <span>{ "100% gently-cooked" } <strong className="font-bold">{ "turkey" }</strong>{ ", with traces of " }<strong className="font-bold">{ "chicken" }</strong></span>,
  productImageAlt: "katkin fresh meal Gobble",
  productImageSrc: "/images/products/carousel/gobble.webp",
};

const moooFreshProductCarouselCard = {
  productName: "MOOO",
  productDescription: <span>{ "100% gently-cooked " }<strong className="font-bold">{ "beef" }</strong></span>,
  productImageAlt: "katkin fresh meal Mooo",
  productImageSrc: "/images/products/carousel/mooo.webp",
};

const oinkFreshProductCarouselCard = {
  productName: "OINK",
  productDescription: <span>{ "100% gently-cooked " }<strong className="font-bold">{ "pork" }</strong></span>,
  productImageAlt: "katkin fresh meal Oink",
  productImageSrc: "/images/products/carousel/oink.webp",
};

const quackFreshProductCarouselCard = {
  productName: "QUACK",
  productDescription: <span>{ "100% gently-cooked " }<strong className="font-bold">{ "duck" }</strong>{ ", with traces of " }<strong className="font-bold">{ "chicken" }</strong></span>,
  productImageAlt: "katkin fresh meal Quack",
  productImageSrc: "/images/products/carousel/quack.webp",
};

const splashFreshProductCarouselCard = {
  productName: "SPLASH",
  productDescription: <span>{ "100% gently-cooked " }<strong className="font-bold">{ "fish" }</strong>{ ", with traces of " }<strong className="font-bold">{ "turkey" }</strong></span>,
  productImageAlt: "katkin fresh meal Splash",
  productImageSrc: "/images/products/carousel/splash.webp",
};

export const productCarouselDataMap = {
  [baaaFreshProductCarouselCard.productName]: baaaFreshProductCarouselCard,
  [cluckFreshProductCarouselCard.productName]: cluckFreshProductCarouselCard,
  [gobbleFreshProductCarouselCard.productName]: gobbleFreshProductCarouselCard,
  [moooFreshProductCarouselCard.productName]: moooFreshProductCarouselCard,
  [oinkFreshProductCarouselCard.productName]: oinkFreshProductCarouselCard,
  [quackFreshProductCarouselCard.productName]: quackFreshProductCarouselCard,
  [splashFreshProductCarouselCard.productName]: splashFreshProductCarouselCard,
};
