import { FC, memo } from "react";
import { SbReactComponentsMap } from "@storyblok/react";
import Script from "next/script";

import { components } from "apps/website/components/storyblok/sections";

import {
  StoryBlokFormQuoteCallout,
} from "../components/storyblok/sections/forms/StoryBlokFormQuoteCallout";
import {
  StoryBlokFormImageAccordionList,
} from "../components/storyblok/sections/forms/StoryBlokFormImageAccordionList";
import {
  StoryBlokFormFakeLoading,
} from "../components/storyblok/sections/forms/StoryBlokFormFakeLoading";
import {
  StoryBlokFormMessageBox,
} from "../components/storyblok/sections/forms/StoryBlokFormMessageBox";
import {
  StoryBlokFormSummaryFuture,
} from "../components/storyblok/sections/forms/StoryBlokFormSummaryFuture";
import {
  StoryBlokFormMicroGallery,
} from "../components/storyblok/sections/forms/StoryBlokFormMicroGallery";
import {
  StoryBlokFormDiscountCodeContent,
} from "../components/storyblok/sections/forms/StoryBlokFormDiscountCodeContent";
import Footer from "../components/page/Footer/Footer";
import DefaultHead from "../components/page/DefaultHead/DefaultHead";
import { makePageTags } from "../utils/website/head";
import SkipToContent from "../components/page/SkipLink/SkipLink";
import Header from "../components/page/Header/Header";
import Main from "../components/layout/Main/Main";
import { IHeaderHistoryNavigation } from "../components/page/Header/Header.map";
import {
  StoryBlokFormConditionalCheckList,
} from "../components/storyblok/sections/forms/StoryBlokFormConditionalCheckList";
import {
  StoryBlokFormClusterTagList,
} from "../components/storyblok/sections/forms/StoryBlokFormClusterTagList";
import {
  StoryBlokFormAlertPrompt,
} from "../components/storyblok/sections/forms/StoryBlokFormAlertPrompt";
import {
  StoryBlokFormIconCallout,
} from "../components/storyblok/sections/forms/StoryBlokFormIconCallout";
import {
  StoryBlokFormCondensedList,
} from "../components/storyblok/sections/forms/StoryBlokFormCondensedList";
import {
  StoryBlokFormFreeFlow,
} from "../components/storyblok/sections/forms/StoryBlokFormFreeFlow";
import {
  StoryBlokFormCancellationFlowRouter,
} from "../components/storyblok/sections/forms/StoryBlokFormCancellationFlowRouter";
import {
  StoryBlokFormStickyCta,
} from "../components/storyblok/sections/forms/StoryBlokFormStickyCTA";
import { AppStoryblokInit } from "../storyblok/get-storyblok-api";
import {
  StoryBlokFormHighlighterCallout,
} from "../components/storyblok/sections/forms/StoryBlokFormHighlighterCallout";
import {
  StoryBlokCancellationDiscount,
} from "../components/storyblok/sections/StoryBlokCancellationDiscount";
import {
  StoryBlokFormComparisonModal,
} from "../components/storyblok/sections/forms/StoryBlokFormComparisonModal";

import { HEADER_SCRIPTS, ILayout } from "./layouts.map";

interface IFlowLayout extends ILayout {
  hideFooter?: boolean;
  historyNavigation?: IHeaderHistoryNavigation;
}

const overrideComponents: SbReactComponentsMap = {
  section_quote_callout: StoryBlokFormQuoteCallout,
  section_image_accordion_list: StoryBlokFormImageAccordionList,
  section_fake_loading: StoryBlokFormFakeLoading,
  section_message_box: StoryBlokFormMessageBox,
  section_summary_future: StoryBlokFormSummaryFuture,
  section_micro_gallery: StoryBlokFormMicroGallery,
  section_cancellation_discount: StoryBlokCancellationDiscount,
  section_discount_code_content: StoryBlokFormDiscountCodeContent,
  section_conditional_check_list: StoryBlokFormConditionalCheckList,
  section_cluster_tag_list: StoryBlokFormClusterTagList,
  section_alert_prompt: StoryBlokFormAlertPrompt,
  section_icon_callout: StoryBlokFormIconCallout,
  section_comparison_modal: StoryBlokFormComparisonModal,
  section_condensed_list: StoryBlokFormCondensedList,
  section_free_flow: StoryBlokFormFreeFlow,
  section_form_cancellation_router: StoryBlokFormCancellationFlowRouter,
  section_sticky_cta: StoryBlokFormStickyCta,
  section_highlighter_callout: StoryBlokFormHighlighterCallout,
};

AppStoryblokInit({
  ...components,
  ...overrideComponents,
});

const FlowLayout: FC<IFlowLayout> = ({
  scripts,
  schemaScripts,
  headerLinks,
  head,
  lastBuilt,
  footer,
  hideFooter = false,
  historyNavigation,
  children,
}) => (<div>
  <DefaultHead
    /* @ts-ignore */
    seo={makePageTags(head?.seo)}
    /* @ts-ignore */
    hideFromSearch={head?.hide_from_search_engines}
    lastBuilt={lastBuilt}
    fallbackTags={head?.fallbackTags && makePageTags(head.fallbackTags)}
  >
    { scripts?.map((script) => (
      <Script key={script} type="text/javascript" src={HEADER_SCRIPTS[script]} async />
    )) }
    { schemaScripts?.map((script) => (
      <Script
        key={script.id}
        id={script.id}
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(script.data),
        }}
      />
    )) }
  </DefaultHead>

  <SkipToContent />

  <Header
    {...headerLinks}
    hasAnnouncementBanner={false}
    historyNavigation={historyNavigation}
  />
  <Main>
    { children }
  </Main>

  { (!hideFooter && footer) && (
    <Footer {...footer} />
  ) }

</div>
);

export default memo(FlowLayout);
