import { IStoryBlokComponent } from "../../page/api.types";
import SectionCancellationDiscount from "../../section/SectionCancellationDiscount/SectionCancellationDiscount";

export interface IStoryBlokCancellationDiscount {
  discount_code: string;
}

export const StoryBlokCancellationDiscount = ({ blok }: IStoryBlokComponent<IStoryBlokCancellationDiscount>) => (
  <SectionCancellationDiscount
    discountCode={blok.discount_code}
  />
);
