import { FC } from "react";

import Container from "apps/website/components/layout/Container/Container";

import Image, { IImageProps } from "../../base/Image/Image";
import TextSubtitle from "../../base/Text/TextSubtitle/TextSubtitle";
import TextControl from "../../base/Text/TextControl/TextControl";

export interface IImageWithBadgeProps extends BadgeProps {
  imageProps: IImageProps
}

interface BadgeProps {
  badgeSubtitle: string
  badgeText: string
}

const Badge: FC<BadgeProps> = ({ badgeSubtitle, badgeText }) => (
  <div className="flex bg-black rounded-full absolute bottom-4 right-0 justify-center items-center aspect-square p-2 mr-2.5 text-center animate--wiggle content-between">
    <div>
      <TextSubtitle className="p-0" color={"secondary"} size={1} align={"center"} whitespace={"preWrap"}>{ badgeSubtitle }</TextSubtitle>
      <TextControl className="leading-3" color={"secondary"} size={3} align={"center"} whitespace={"preWrap"}>{ badgeText }</TextControl>
    </div>
  </div>
);

const ImageWithBadge: FC<IImageWithBadgeProps> = ({ imageProps, badgeSubtitle, badgeText }) => (
  <div data-component={ImageWithBadge.name}>
    <Container padding="none" className="relative h-auto w-auto" >
      <Image image={imageProps} alt={imageProps.alt ?? ""}/>
      <Badge badgeSubtitle={badgeSubtitle} badgeText={badgeText}/>
    </Container>
  </div>
);

export default ImageWithBadge;
