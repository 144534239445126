import { IStoryBlokImage } from "@forms/schema";
import { getStoryBlokImage } from "apps/website/utils/storyblok/image";
import { Theme } from "apps/website/maps/Theme.map";

import { IStoryBlokComponent } from "../../page/api.types";
import SectionImageWithBadge from "../../section/SectionImageWithBadge/SectionImageWithBadge";

export interface IStoryBlokImageWithBadgeFeature {
  story_blok_image: IStoryBlokImage;
  badge_text: string
  badge_subtitle: string
}

export interface IStoryBlokImageWithBadgeSection {
  theme: Theme;
  image_with_badge : [IStoryBlokImageWithBadgeFeature]
}

export const StoryBlokImageWithBadge = ({ blok }: IStoryBlokComponent<IStoryBlokImageWithBadgeSection>) => {

  const imageWithBadge = blok.image_with_badge[0];

  return (
    <SectionImageWithBadge
      imageWithBadgeProps={
        {
          imageProps: getStoryBlokImage(imageWithBadge.story_blok_image, true, 1432),
          badgeText: imageWithBadge.badge_text,
          badgeSubtitle: imageWithBadge.badge_subtitle,
        }
      }
      theme = {blok.theme}
    ></SectionImageWithBadge>
  );
};
