import { MealPlanType } from "./cats";
import { ExtraProduct, Product } from "./products";

type IntervalUnit = "weeks" | "days";
type TimestampString = string;
type DiscountSettingKey = "api" | "checkout_page" | "customer_portal" | "merchant_portal";

export const PLAN_TYPES: Record<string, string> = {
  MEAL: "meal",
  LITTER: "litter",
};

export const MEAL_PLAN_PRETTY_TITLES: Partial<Record<MealPlanType, string>> = {
  Plan14x14: "Freezer plan",
  Plan28x28: "Standard plan",
  Plan28x56: "Half meal plan",
  Plan42x42: "Saver plan",
};

type PlanKey = keyof typeof PLAN_TYPES;
export type PlanType = typeof PLAN_TYPES[PlanKey];

export interface IRecipe {
  subscriptionId: string;
  productTitle: Product;
  quantity: number;
  itemCost?: number;
}

export interface IExtra {
  subscriptionId: number | string,
  productTitle: Product,
  quantity: number,
  itemCost: number,
  totalCost?: number,
  totalCostPerDay?: number
}

export type TBoxNumber = "Trial" | number;
export type TParentProduct = "KATKIN_FRESH_MEAL_PLAN" | "ADD_ON_PLAN";

export interface IBaseSubscription {
  parentId: number;
  inProgressDeliveryDate: TimestampString | null;
  nextDeliveryDate: TimestampString | null;
  lastChargeAttemptDate: TimestampString | null;
  lastChargeDate: TimestampString | null;
  extras: IExtra[];
  discountAmount: number | null;
  editDeliveryDateUntil: string | null; // will be null if the user has cancelled
  planCost: number;
  totalCostWithoutDiscount: number;
  totalCostWithDiscount: number;
  interval: number;
  intervalUnit: IntervalUnit,
  planCostPerDay: number;
  deliveryCost: number;
  boxNumber: TBoxNumber;
  parentProductTitle: TParentProduct;
}

export type VariablePricingKey = number | "default";

export interface IVariablePricing {
  discount: number;
  unitPrice: number;
  quantity: number;
}

export type IQuantityToPrice = {
  quantityToPrice: Record<VariablePricingKey, IVariablePricing>
};

export interface IFreshMealPlan extends IBaseSubscription {
  mealPlanType: MealPlanType;
  mealPlanSize: "A" | "B" | "C" | "D" | "E" | "F";
  catId: string;
  catName: string;
  recipes: IRecipe[];
  calories: number;
}

export interface IMappedLitterPlan {
  parentId: number;
  id: Product;
  name: string | undefined;
  quantity: number;
  interval: number;
  intervalUnit: string;
  cost: number;
  pricePerDay?: number;
  deliveryCost: number;
  addonPlanType: ExtraProduct;
  savings: number | undefined;
  discount: number | undefined;
}

export interface ILitterPlan extends IBaseSubscription {
  name: string;
  quantity: number;
  catIds: string[];
  cost: number;
  pricePerDay: number;
  addonPlanType: ExtraProduct;
  totalCostPerDay: number;
  savings?: number;
}

export interface IDiscountChannelSettings {
  can_apply: boolean;
}

export interface IDiscount {
  code: string;
  discount_type: "percentage" | "fixed_amount" | "absolute_total";
  duration: string;
  status: string;
  value: number;
  id: number;
  applies_to_product_type: string;
  channel_settings: Record<DiscountSettingKey, IDiscountChannelSettings>;
  created_at: TimestampString | null;
  once_per_customer: boolean;
  times_used: number;
  updated_at: TimestampString | null;
}

export interface IDiscountData {
  discount: IDiscount;
  forChargeDate: TimestampString | null;
  appliesToProducts: null;
  appliesToSubGroups: Record<string, number>;
}

export interface IMySubscriptions {
  freshMealPlans: IFreshMealPlan[];
  litterPlans: ILitterPlan[];
  discountData?: IDiscountData;
}

export function isFreshMealPlan(plan: IBaseSubscription): plan is IFreshMealPlan {
  return plan.parentProductTitle === "KATKIN_FRESH_MEAL_PLAN";
}

export function isLitterPlan(plan: IBaseSubscription): plan is ILitterPlan {
  return plan.parentProductTitle === "ADD_ON_PLAN";
}

export interface ISubscriptionPreviewItem {
  id: number | string;
  name: string;
  image: string;
  quantity: number
}

export interface ActivateSubscription {
  result: ActivateSubscriptionResult;
  message: string;
  statusCode: number;
  catNames?: string[];
  addonPlans?: ExtraProduct[];
  gender?: "BOY" | "GIRL" | "UNKNOWN";
}

export type ActivateSubscriptionResult = "SubscriptionActivationSuccessMigratedExistingRecipes"
| "SubscriptionActivationSuccessMigratedNewRecipes"
| "CouldNotMigrateRecipesForCats"
| "SubscriptionActivationSuccessNormal"
| "SubscriptionActivationSuccessAddonOnly";

export type OrderType = "ONETIME" | "TRIAL" | "REPEAT";

export type SubscriptionType = "KATKIN_FRESH_MEAL_PLAN" | "ADD_ON_PLAN";

export type SubscriptionStatus = "ACTIVE" | "CANCELLED";

export interface IDelaySuccessResult {
  subToDelivery: Record<number, string>,
}

export interface IShippingAddress {
  address1?: string
  address2?: string
  city?: string
  company?: string
  country?: string
  first_name?: string
  last_name?: string
  phone?: string
  province?: string
  zip?: string
}

export interface IOrderReceipt {
  deliveryDate?: string,
  deliveryPrice?: number;
  chargeDate: string,
  totalDiscounts?: string,
  totalPrice: string,
  totalTax?: number,
  totalWeight?: number,
  shippingAddress?: IShippingAddress,
}

export interface ICustomerOrder {
  chargeDate: string,
  deliveryDate: string,
  orderNumber: number,
  shopifyOrderName?: string,
  amount: string,
  orderStatus?: "SUCCESS" | "ERROR" | "QUEUED" | "SKIPPED" | "REFUNDED" | "PARTIALLY_REFUNDED" | "PENDING_MANUAL_PAYMENT" | "PENDING",
  receipt?: IOrderReceipt,
  totalRecipes: number,
  deliveryStatus?: DeliveryStatus,
}

export type DeliveryStatus = "SUCCESS" | "IN_PROGRESS" | "QUEUED";

export interface CustomerAddresses {
  shippingAddress: RechargeShippingAddress;
  billingAddress: RechargeBillingAddress;
}
interface RechargeShippingAddress {
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  province?: string;
  zip?: string;
}

interface RechargeBillingAddress {
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  province?: string;
  zip?: string;
}

export interface SimpleCardDetails {
  last4Digits: string;
  cardholderName?: string;
  cardBrand: string;
  cardExpiry: string;
  lastPaymentFailed?: boolean;
}

export const getNextDeliveryDate = (plan: IFreshMealPlan | ILitterPlan): string | null => (
  plan.inProgressDeliveryDate || plan.nextDeliveryDate
);

export const isSubscriptionActive = (plan: IFreshMealPlan | ILitterPlan): boolean => (
  !!plan.inProgressDeliveryDate || !!plan.nextDeliveryDate
);
