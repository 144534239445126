"use client";

import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { usePathname, useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import { CookieSetOptions } from "universal-cookie";

import { useAuth, useDatadog } from "@auth/client-sdk-react";
import { fbcFromFbclidQueryParam } from "@capi/types";
import { IPlanSummaryResponse } from "@forms/schema";

import { useAPI } from "../hooks/useAPI";
import { PublicConfig } from "../config/public";
import { useAnalytics } from "../hooks/useAnalytics";

type TrackPageViewContext = {
  trackPageView(): void;
  checkoutInitiated(data: {
    planSummaryResponse: IPlanSummaryResponse,
    customerId: string,
    customerEmail: string | undefined,
    customerFirstName: string | undefined,
    customerLastName: string | undefined,
    postcode: string | undefined,
    city: string | undefined,
    phoneNumber: string | undefined,
  }): Promise<void>;
  lastPageLogged: string | undefined;
  pageLoggedAt: number;
};

const trackPageViewContext = createContext<TrackPageViewContext>(undefined as unknown as TrackPageViewContext);

export const useTrackPageView = () => useContext(trackPageViewContext);

function cookieDomainSettings(
  expires?: dayjs.Dayjs | undefined,
  maxAge?: number | undefined,
): CookieSetOptions {
  const cookieDomain = PublicConfig.NEXT_PUBLIC_COOKIE_DOMAIN;
  const secure = cookieDomain !== "localhost";
  return {
    path: "/",
    domain: cookieDomain,
    secure,
    sameSite: secure ? "none" : "strict",
    expires: expires?.toDate(),
    maxAge,
  };
}

function randomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const WebsiteTracking: FC<PropsWithChildren> = ({
  children,
}) => {

  const router = useRouter();
  const pathname = usePathname();
  const analytics = useAnalytics();

  const {
    loggedIn,
    accessToken,
    user,
  } = useAuth();

  const posthog = usePostHog();
  const datadog = useDatadog();
  const api = useAPI();
  const [ cookies, setCookie ] = useCookies([ "_fbp", "_fbc", "logged_in_v1", "manage_plan_v2" ]);

  useEffect(() => {

    if (user && loggedIn) {
      posthog.identify(
        user.impersonationId || user.legacyId,
        {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      );
      if (user.email) datadog?.setGlobalContextProperty("kk_email", user.email);
      if (user?.impersonationId || user?.legacyId) datadog?.setGlobalContextProperty("kk_customer_id", user.impersonationId || user.legacyId);
      if (user.id) datadog?.setGlobalContextProperty("token_sub", user.id);
    }
    // NOTE: We do not call posthog resets and clearGlobalContext here, because
    // the useUpdateEffect might just run prematurely before full auth hydration
    // Those resets are handled in a callback to the auth context provider instead
  }, [
    user,
    posthog,
    datadog,
    loggedIn,
  ]);

  const [ lastPageLogged, setLastPageLogged ] = useState<string | undefined>();
  const [ pageLoggedAt, setPageLoggedAt ] = useState<number>(Date.now());
  const capiPageview = useCallback(() => {
    if (loggedIn === undefined || lastPageLogged === pathname) {
      return;
    }

    let fbp = cookies._fbp?.toString()?.trim();
    // Generate an _fbp cookie if it does not exist
    if (!fbp) {
      fbp = `fb.1.${Date.now()}.${randomInteger(1000000000, 2147483647)}`;
      setCookie(
        "_fbp",
        fbp,
        // Keep for 90 days
        cookieDomainSettings(
          undefined,
          90 * 24 * 60 * 60,
        ),
      );
    }

    // Gets the fbclid query parameter and moves it to the _fbc cookie
    // eslint-disable-next-line no-console
    const fbcResult = fbcFromFbclidQueryParam(window.location.href, console.error);
    // If we found an fbc query parameter
    if (fbcResult) {
      // And either the fbc cookie is not set
      if (!cookies._fbc
        // Or the fbc cookie is set but has a different (most likely older) fbclid
        || !cookies._fbc.toString().endsWith(fbcResult.fbclidQuery)) {
        setCookie(
          "_fbc",
          fbcResult.fbc,
          // Set as a session cookie
          cookieDomainSettings(),
        );
      }
      // Regardless of whether we set the fbc cookie, we should still remove the fbclid query parameter
      // (actually, don't remove it, since other analytics tools might want to read it, it should be removed anyway
      // when we navigate to another page)
      // fbcResult.parsedUrl.searchParams.delete("fbclid");
      // const updatedUrl = fbcResult.parsedUrl.href;
      // window.history.replaceState({ path: updatedUrl }, "", updatedUrl);
    }

    setLastPageLogged(pathname);
    setPageLoggedAt(Date.now());
    void api.Capi.pageview(window.location.href, accessToken, {
      fbc: fbcResult?.fbc || cookies._fbc,
      fbp: fbp || cookies._fbp,
      email: user?.email,
      firstName: user?.email,
      lastName: user?.lastName,
      externalIds: user?.legacyId ? [ user.legacyId ] : undefined,
    }).catch(
      // Don't let tracking failures crash the app
      () => {},
    );
  }, [
    lastPageLogged,
    accessToken,
    loggedIn,
    pathname,
    api.Capi,
    cookies._fbc,
    cookies._fbp,
    setCookie,
    user,
  ]);

  // Global-level hook which sets up Logged in Experience V1 routing cookie
  useEffect(() => {
    // Already been given a group
    if (cookies.manage_plan_v2 !== undefined) {
      return;
    }

    posthog.onFeatureFlags(() => {
      const managePlansFeatureFlag = posthog.getFeatureFlag("manage-plan-v2-routing");

      if (managePlansFeatureFlag === "test") {
        setCookie("manage_plan_v2", true);
      } else {
        setCookie("manage_plan_v2", false);
      }

    });
  }, [ posthog, router, cookies.manage_plan_v2, setCookie ]);

  const trackPageView = useCallback(() => {
    if (process.env.NODE_ENV !== "production") return;
    posthog.capture("$pageview");
    void analytics.page();
    void capiPageview();
  }, [ analytics, posthog, capiPageview ]);

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;

    // Track the initial page view
    void trackPageView();

  }, [ trackPageView, pathname ]);

  const checkoutInitiated: TrackPageViewContext["checkoutInitiated"] = useCallback(async ({
    planSummaryResponse,
    customerId,
    customerEmail,
    customerFirstName,
    customerLastName,
    postcode,
    city,
    phoneNumber,
  }): Promise<void> => {
    if (process.env.NODE_ENV !== "production") return;
    const eventId = `${customerId}_${pageLoggedAt}`;
    const finalEmail = customerEmail?.trim() || user?.email?.trim() || undefined;
    const finalFirstName = customerFirstName?.trim() || user?.firstName?.trim() || undefined;
    const finalLastName = customerLastName?.trim() || user?.lastName?.trim() || undefined;
    const finalPostcode = postcode?.trim() || undefined;
    const finalPhone = phoneNumber?.trim() || undefined;
    await api.Capi.initiateCheckout(
      window.location.href,
      accessToken,
      eventId,
      planSummaryResponse.capiCustomData,
      {
        fbc: fbcFromFbclidQueryParam(window.location.href, console.error)?.fbc || cookies._fbc,
        fbp: cookies._fbp,
        email: finalEmail,
        firstName: finalFirstName,
        lastName: finalLastName,
        externalIds: [ customerId ],
        postcode: finalPostcode,
        city: city?.trim() || undefined,
        phone: finalPhone,
      },
    );
    const listId = `${planSummaryResponse.capiCustomData?.contentCategory}_${planSummaryResponse.capiCustomData?.contentName}`;
    if (typeof window !== undefined && window?.dataLayer) {
      try {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: `rc_rechargeCheckout_${listId}`,
          customerEmail: finalEmail,
          customerFirstName: finalFirstName,
          customerLastName: finalLastName,
          customerPostcode: finalPostcode,
          customerPhone: finalPhone,
          katkinCustomerId: customerId,
          kkcoupon: planSummaryResponse.capiCustomData?.searchString,
          kktransactionId: eventId,
          kkrevenue: planSummaryResponse.capiCustomData?.value?.toFixed(2),
          ecommerce: {
            userId: customerId,
            currencyCode: planSummaryResponse.capiCustomData?.currency,
            purchase: {
              actionField: {
                id: eventId,
                affiliation: window.location.hostname,
                revenue: planSummaryResponse.capiCustomData?.value?.toFixed(2),
                // 20% tax calculation
                tax: typeof planSummaryResponse.capiCustomData?.value === "number"
                  ? ((planSummaryResponse.capiCustomData.value * 20) / 120).toFixed(2)
                  : undefined,
                shipping: planSummaryResponse.capiCustomData?.contents
                  ?.filter((it) => it.id === "Delivery Fee")
                  ?.map((it) => it.itemPrice)
                  ?.reduce((a, b) => (a || 0) + (b || 0), 0),
                coupon: planSummaryResponse.capiCustomData?.searchString,
                option: planSummaryResponse.capiCustomData?.searchString,
                list: listId,
              },
              products: planSummaryResponse.capiCustomData?.contents?.map((item) => ({
                name: item.id,
                id: item.id,
                productId: item.id,
                price: item.itemPrice,
                brand: item.brand,
                variant: item.id,
                quantity: item.quantity,
              })),
            },
          },
        });
      } catch (err) {
        console.error("Failed to track checkoutInitiated");
        console.error(err);
      }
    }
  }, [
    accessToken,
    api.Capi,
    cookies._fbc,
    cookies._fbp,
    user,
    pageLoggedAt,
  ]);

  const context = useMemo<TrackPageViewContext>(() => ({
    trackPageView,
    lastPageLogged,
    pageLoggedAt,
    checkoutInitiated,
  }), [ lastPageLogged, pageLoggedAt, trackPageView, checkoutInitiated ]);

  return (
    <trackPageViewContext.Provider value={context}>
      { children }
    </trackPageViewContext.Provider>
  );
};
