import {
  ExtraProduct,
  ExtraFreshProduct,
} from "libs/api/customer/src/lib/types/products";

export enum EProduct {
  CLUCK,
  GOBBLE,
  QUACK,
  MOOO,
  SPLASH,
  OINK,
  BAAA,
}

export enum EProductCategory {
  FRESH,
  SCOOP,
  UNKNOWN,
}

/**
 * Filename for each product should be consistent across all folders e.g. cluck.webp
 */
interface IProductFileNames {
  image: string;
}

interface IProductIngredients {
  core: string[];
}

interface IProduct {
  key: string;
  title: string;
  ingredients: IProductIngredients;
  productCategory: EProductCategory;
  fileNames: IProductFileNames;
}

export type TProductMap = Record<EProduct, IProduct>;

export const PRODUCT_MAP: TProductMap = {
  [EProduct.CLUCK]: {
    key: "CLUCK",
    title: "Cluck",
    ingredients: {
      core: [ "chicken" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "cluck.webp",
    },
  },
  [EProduct.GOBBLE]: {
    key: "GOBBLE",
    title: "Gobble",
    ingredients: {
      core: [ "turkey" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "gobble.webp",
    },
  },
  [EProduct.QUACK]: {
    key: "QUACK",
    title: "Quack",
    ingredients: {
      core: [ "duck" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "quack.webp",
    },
  },
  [EProduct.MOOO]: {
    key: "MOOO",
    title: "Mooo",
    ingredients: {
      core: [ "beef" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "mooo.webp",
    },
  },
  [EProduct.SPLASH]: {
    key: "SPLASH",
    title: "Splash",
    ingredients: {
      core: [ "fish", "turkey" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "splash.webp",
    },
  },
  [EProduct.OINK]: {
    key: "OINK",
    title: "Oink",
    ingredients: {
      core: [ "pork" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "oink.webp",
    },
  },
  [EProduct.BAAA]: {
    key: "BAAA",
    title: "Baaa",
    ingredients: {
      core: [ "lamb" ],
    },
    productCategory: EProductCategory.FRESH,
    fileNames: {
      image: "baaa.webp",
    },
  },
};

export const EXTRA_PRODUCT: Partial<Record<ExtraProduct, string>> = {
  HEALTH_SCOOP_LITTER: "Scoop Health",
  PLANET_SCOOP_LITTER: "Scoop Planet",
};

export const EXTRA_FRESH_PRODUCT: Partial<Record<ExtraFreshProduct, string>> = {
  NIBBLES_CHICKEN: "Chicken Nibbles",
  NIBBLES_SALMON: "Salmon Nibbles",
  SPRINKLES: "Sprinkles",
};

export const VALID_SCOOP_DELIVERY_INTERVALS: number[] = Array.from({ length: 16 }, (_, value) => (value + 1));
