import { SwiperSlide } from "swiper/react";

import Column from "apps/website/components/layout/Column/Column";
import Carousel from "apps/website/components/feature/Carousel/Carousel";

import FreshProductCarouselCard from "./FreshProductCarouselCard";
import { productCarouselDataMap } from "./constants";

const FreshProductCarousel: React.FC<{ values: Record<string, string>, linkingId: string }> =
   ({ values, linkingId }) => {
     const recipes = (values.$defaultRecipes ?? "").split(",").filter(Boolean);
     const productCarouselData = recipes.map((recipeName) => productCarouselDataMap[recipeName]);

     if (!productCarouselData.length) {
       return null;
     }

     return (
       <Column className="bg-light-grey" component="FreshProductCarousel" data-linkingid={linkingId}>
         <Carousel showNavigation rows={1} spaceBetween={"none"} pagination={false} breakpoints={{ default: 2, sm: 3, "2xl": 3.5 }} navigationStyle="secondary">
           { productCarouselData.map((data) => (
             <SwiperSlide key={data.productName} className="flex" style={{ height: "auto" }}>
               <FreshProductCarouselCard
                 productName={data.productName}
                 productDescription={data.productDescription}
                 productImageAlt={data.productImageAlt}
                 productImageSrc={data.productImageSrc}
               />
             </SwiperSlide>
           )) }
         </Carousel>
       </Column>
     );
   };

export default FreshProductCarousel;
